@keyframes easeIn {
  0% {
    transform: translateY(-3rem); }
  100% {
    transform: translateY(0); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }
  *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth; }

body {
  font-family: "Mulish", sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 400;
  overflow-x: hidden; }

.layout {
  background-color: #fafafa;
  min-height: 100vh;
  width: 100%; }

.navigation {
  box-shadow: 0 0.3rem 0.8rem rgba(196, 193, 193, 0.3);
  flex-grow: 1;
  background-color: #fff;
  padding: 2rem 3rem;
  position: sticky;
  top: 0; }
  @media only screen and (max-width: 25em) {
    .navigation {
      padding-left: 2rem; } }
  .navigation a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none; }
    .navigation a i {
      display: block;
      font-size: 2.4rem;
      color: #2196f3; }
    .navigation a p {
      font-size: 1.6rem;
      font-weight: 900;
      color: #2196f3;
      padding-right: 0.8rem; }

.bulksms {
  background-color: rgba(31, 52, 77, 0.05);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 1rem; }
  @media only screen and (max-width: 50em) {
    .bulksms {
      padding: 0; } }
  .bulksms__container {
    width: 70%;
    min-height: 80vh;
    border-radius: 0.8rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 56.25em) {
      .bulksms__container {
        width: 80%; } }
    @media only screen and (max-width: 50em) {
      .bulksms__container {
        width: 100%;
        border-radius: 0;
        padding: 0 0.8rem;
        padding-bottom: 3rem; } }

.bulksms__to-total-contact-wrapper {
  box-shadow: 0 0.2rem 0.8rem rgba(196, 193, 193, 0.4);
  padding: 0 3rem;
  background-color: #fff;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media only screen and (max-width: 43.75em) {
    .bulksms__to-total-contact-wrapper {
      padding: 2rem 0.8rem; } }
  .bulksms__to-total-contact-wrapper p {
    font-size: 1.4rem;
    color: #212121;
    font-weight: 800; }
    .bulksms__to-total-contact-wrapper p span {
      background-color: rgba(33, 150, 243, 0.1);
      color: #2196f3;
      padding: 0.5rem 2rem;
      border-radius: 0.3rem;
      margin-left: 1rem; }

.bulksms__to {
  box-shadow: 0 0.3rem 0.6rem rgba(196, 193, 193, 0.4);
  padding: 0 3rem;
  background-color: #fff;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  margin-top: 2rem; }
  @media only screen and (max-width: 43.75em) {
    .bulksms__to {
      padding: 0 0.8rem; } }
  .bulksms__to-heading {
    font-size: 1.6rem;
    color: #212121;
    font-weight: 700;
    padding: 0.5rem 0; }
    .bulksms__to-heading-wrapper {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media only screen and (max-width: 37.5em) {
        .bulksms__to-heading-wrapper {
          padding: 2rem 0; } }
  .bulksms__to-navigation {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 25em) {
      .bulksms__to-navigation {
        justify-content: space-between; } }
    .bulksms__to-navigation-item {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      cursor: pointer;
      border-bottom: 1px solid transparent; }
      @media only screen and (max-width: 37.5em) {
        .bulksms__to-navigation-item {
          padding: 0.8rem 1rem; } }
      .bulksms__to-navigation-item i {
        display: block;
        margin-right: 1rem;
        color: #c4c1c1;
        font-size: 2.4rem; }
        @media only screen and (max-width: 37.5em) {
          .bulksms__to-navigation-item i {
            font-size: 1.6rem;
            margin-right: 0.3rem; } }
      .bulksms__to-navigation-item p {
        font-size: 1.5rem;
        font-weight: 700;
        color: #212121; }
        @media only screen and (max-width: 37.5em) {
          .bulksms__to-navigation-item p {
            font-size: 1.3rem; } }
    .bulksms__to-navigation-item-active {
      border-bottom: 1px solid #2196f3; }
      .bulksms__to-navigation-item-active i {
        color: #2196f3; }
      .bulksms__to-navigation-item-active p {
        color: #2196f3; }

.bulksms__to-contact {
  border: 1px solid #eeeeee;
  margin: 2rem 0;
  border-radius: 0.8rem;
  padding: 1rem;
  height: 100%;
  overflow-y: auto; }
  .bulksms__to-contact-container {
    height: 30vh;
    padding-bottom: 1rem; }
  .bulksms__to-contact-message {
    font-size: 1.2rem;
    font-weight: 500;
    color: #212121;
    padding-bottom: 1rem; }
  .bulksms__to-contact-number {
    background-color: #eeeeee;
    border-radius: 0.2rem;
    border: 1px solid #eeeeee;
    padding: 0.5rem 0.8rem;
    font-size: 1.2rem;
    color: #212121;
    margin: 0.2rem;
    border-radius: 0.3rem;
    display: inline-block; }
    .bulksms__to-contact-number i {
      display: inline-block;
      margin: 0 0.5rem;
      font-size: 1.6rem;
      color: #c4c1c1; }
  .bulksms__to-contact-form {
    display: inline-block; }
    .bulksms__to-contact-form input {
      display: inline-block;
      border: none;
      outline: none;
      padding: 0.5rem 0.8rem;
      font-family: inherit;
      text-transform: capitalize;
      color: #212121;
      margin-right: 0.5rem;
      margin-left: 0.3rem;
      width: 11rem;
      border: 1px solid #e0e0e0;
      border-radius: 0.2rem; }
      .bulksms__to-contact-form input:focus {
        border: 1px solid #2196f3; }
    .bulksms__to-contact-form button {
      display: inline-block;
      border: none;
      outline: none;
      color: #fff;
      border: 1px solid #2196f3;
      background-color: #2196f3;
      padding: 0.5rem 1.8rem;
      font-family: inherit;
      border-radius: 0.2rem;
      font-weight: 900;
      width: 11rem;
      margin: 0.3rem; }

.bulksms__to-file-contact {
  border: 1px solid #eeeeee;
  margin: 2rem 0;
  height: 40vh;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .bulksms__to-file-contact-number {
    background-color: #eeeeee;
    border-radius: 0.2rem;
    border: 1px solid #eeeeee;
    padding: 0.5rem 0.8rem;
    font-size: 1.3rem;
    color: #212121;
    margin: 0.2rem;
    border-radius: 0.3rem;
    display: inline-block;
    font-weight: 400; }
    .bulksms__to-file-contact-number i {
      display: inline-block;
      margin: 0 0.5rem;
      font-size: 1.6rem;
      color: #c4c1c1; }
  .bulksms__to-file-contact-container {
    padding: 2rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
  .bulksms__to-file-contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .bulksms__to-file-contact-info i {
      color: #c4c1c1;
      font-size: 3.2rem;
      display: block;
      margin: 1rem auto; }
    .bulksms__to-file-contact-info button {
      padding: 1rem 3rem;
      border: none;
      outline: none;
      font-family: inherit;
      border: 1px solid #2196f3;
      color: #2196f3;
      background-color: transparent;
      font-weight: 700;
      border-radius: 0.3rem;
      margin: 1rem 0; }
  .bulksms__to-file-contact p {
    color: #2196f3;
    font-weight: 700;
    font-size: 1.4rem; }
  .bulksms__to-file-contact label p {
    padding: 0.8rem 7rem;
    border: 1px solid #2196f3;
    border-radius: 0.5rem;
    margin: 1rem; }
  .bulksms__to-file-contact label i {
    margin-right: 0.8rem;
    color: #2196f3;
    font-size: 2.4rem; }
  .bulksms__to-file-contact input {
    display: none; }

.bulksms__message {
  box-shadow: 0 0.3rem 0.6rem rgba(196, 193, 193, 0.4);
  padding: 0 3rem;
  background-color: #fff;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media only screen and (max-width: 43.75em) {
    .bulksms__message {
      padding: 0 0.8rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }
  .bulksms__message-heading {
    font-size: 1.4rem;
    font-weight: 700;
    color: #212121; }
  .bulksms__message-container {
    margin: 2rem 0;
    min-height: 20vh;
    border-radius: 0.8rem;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap; }
    .bulksms__message-container p {
      font-size: 1.4rem;
      font-weight: 600;
      color: #212121; }
    .bulksms__message-container textarea {
      border: none;
      outline: none;
      border: 1px solid #eeeeee;
      width: 12rem;
      font-family: inherit;
      color: #212121;
      padding: 1rem 2rem;
      width: 100%;
      min-height: 20vh;
      border-radius: 0.8rem; }
      .bulksms__message-container textarea:focus {
        border: 1px solid #2196f3; }

.bulksms__senderid-wrapper {
  box-shadow: 0 0.3rem 0.6rem rgba(196, 193, 193, 0.4);
  padding: 0 3rem;
  background-color: #fff;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media only screen and (max-width: 43.75em) {
    .bulksms__senderid-wrapper {
      padding: 0 0.8rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }
  @media only screen and (max-width: 25em) {
    .bulksms__senderid-wrapper {
      flex-direction: column;
      align-items: stretch; }
      .bulksms__senderid-wrapper button {
        margin-top: 1rem; } }

.bulksms__senderid p {
  font-size: 1.4rem;
  font-weight: 800;
  color: #212121;
  padding-bottom: 1rem; }

.bulksms__senderid input {
  padding: 1.5rem 2.2rem;
  border: none;
  border: 1px solid #eeeeee;
  outline: none;
  font-family: inherit;
  color: #212121;
  border-radius: 0.3rem; }
  @media only screen and (max-width: 25em) {
    .bulksms__senderid input {
      width: 100%; } }
  .bulksms__senderid input:focus {
    border: 1px solid #2196f3; }

.bulksms__sendmessage-button {
  border: none;
  outline: none;
  padding: 1.5rem 2.2rem;
  background-color: transparent;
  background-color: #2196f3;
  border-radius: 0.5rem;
  font-family: inherit;
  font-weight: 900;
  color: #fff;
  transition: all 0.2s; }
  .bulksms__sendmessage-button:active, .bulksms__sendmessage-button:hover {
    background-color: rgba(25, 118, 210, 0.5); }

.bulksms__popup {
  position: fixed;
  top: 0;
  background-color: rgba(33, 33, 33, 0.95);
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .bulksms__popup-message-box {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 0.3rem;
    min-width: 30%;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media only screen and (max-width: 37.5em) {
      .bulksms__popup-message-box {
        min-width: 60%; } }
    @media only screen and (max-width: 25em) {
      .bulksms__popup-message-box {
        min-width: 80%; } }
    .bulksms__popup-message-box i {
      font-size: 3.2rem;
      color: #2196f3;
      display: block; }
    .bulksms__popup-message-box button {
      padding: 1rem 2rem;
      border: none;
      outline: none;
      border: 1px solid #2196f3;
      font-family: inherit;
      font-weight: 700;
      color: #2196f3;
      border-radius: 0.3rem;
      min-width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      margin: 0.8rem 0; }
    .bulksms__popup-message-box p {
      font-weight: 600;
      color: #212121;
      font-size: 1.4rem;
      padding: 0.8rem 0; }

.spinner {
  margin: 2rem auto;
  width: 4rem;
  height: 4rem;
  border: 0.2rem solid rgba(33, 150, 243, 0.1);
  border-right: 0.2rem solid #2196f3;
  border-radius: 50%;
  animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.bulksms__clear-btn {
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  font-family: inherit;
  background-color: #c71450;
  color: #fff;
  font-weight: 800;
  border-radius: 0.3rem;
  transition: all 0.2s;
  text-transform: uppercase;
  font-size: 1.3rem; }
  .bulksms__clear-btn:active, .bulksms__clear-btn:hover {
    background-color: rgba(244, 67, 54, 0.5); }
  .bulksms__clear-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0; }
